<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Fichar y registro horario</h1>

          <mat-form-field appearance="outline" style="width: 180px;">
            <span matPrefix><mat-icon>person</mat-icon></span>
            <input [formControl]="userAutocompleteControl" type="text" placeholder="Buscar usuario..." matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnUser" (optionSelected)="onUserAutocompleteSelected($event)">
              @for (user of usersFiltered; track user) {
                <mat-option [value]="user">{{user.name + ' ' + user.surnames}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100px;">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card">
        <div class="bisual-table">
          <div class="bisual-table-row-header">
            <div class="bisual-table-col">Id</div>
            <div class="bisual-table-col">Entrada/Salida</div>
            <div class="bisual-table-col">Tipo</div>
            <div class="bisual-table-col">Fecha</div>
            <div class="bisual-table-col">Mood</div>
            <div class="bisual-table-col"></div>
          </div>
          @for (clock of clockRegisters; track clock) {
            <div class="bisual-table-row">
              <div class="bisual-table-col">
                <div class="semaforo" [ngClass]="[ clock.journal_clock_type==='rest' ? 'rest' : clock.flag ]"></div>
                #{{ clock.id }}
              </div>
              <div class="bisual-table-col" style="display: flex; align-items: center;">
                <span style="margin-right: 5px">{{ clock.type=="in" ? "Entrada" : (clock.type=="out" ? "Salida" : "Tipo no identificado") }}</span>
                <mat-icon [ngStyle]="clock.type=='out' ? { 'transform': 'rotate(180deg)', 'color': 'var(--warn-color)' } : { 'color': 'var(--green)' }">trending_flat</mat-icon>
              </div>
              <div class="bisual-table-col" style="display: flex; align-items: center;">
                {{ clock.journal_clock_type==='schedule' ? 'Fichaje' : ( clock.journal_clock_type==='rest' ? 'Descanso' : 'Desconocido' ) }}
              </div>
              <div class="bisual-table-col">{{ clock.created_at | date:'short' }}</div>
              <div class="bisual-table-col">
                @if (clock.clock_register_mood) {
                  <span class="emoji" [ngClass]="clock.clock_register_mood.mood_type">{{ clock.clock_register_mood.emoji }}</span>
                }
                @if (clock.is_from_office!=null && !clock.is_from_office) {
                  @if (clock.should_be_from_office) {
                    <span class="m-1 info-emoji" [matTooltip]="'Fuera de la oficina, y no debería'">
                      🏢
                      <span class="suffix-info-emoji">❌</span>
                    </span>
                  } @else {
                    <span class="m-1" [matTooltip]="'Fuera de la oficina'">🏡</span>
                  }
                }
                @if (clock.device!=null && clock.device=='mobile') {
                  <span class="m-1" [matTooltip]="'Desde el móvil'">📱</span>
                }
              </div>
              <div class="bisual-table-col"
                          [matTooltip]="clock.clock_register_review_requests.length==0 ? '' : (clock.clock_register_review_requests[clock.clock_register_review_requests.length-1].status=='pending') ? 'Pendiente de validación, solicitado: ' + transformDate(clock.clock_register_review_requests[clock.clock_register_review_requests.length-1].proposed_date)
                          : ((clock.clock_register_review_requests[clock.clock_register_review_requests.length-1].status=='approved') ? 'Aceptado' : 'Rechazado')"
                >
                @if (clock.journal_clock_type==='schedule') {
                  <button
                    [disabled]="!(clock.clock_register_review_requests.length<1 || clock.clock_register_review_requests[clock.clock_register_review_requests.length-1].status!='pending')" mat-icon-button (click)="requestReview(clock.id)">
                    <mat-icon
                    [class]="'status-' + (clock.clock_register_review_requests.length>0 ? clock.clock_register_review_requests[clock.clock_register_review_requests.length-1].status : 'empty')">manage_history</mat-icon>
                  </button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

</ng-container>
