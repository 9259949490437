import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Department } from '../models/department.model';
import { DepartmentCategory } from '../models/department_category.model';
import { Role } from '../models/role.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { ArrayResponse } from '../models/array_response.interface';
import { MyProjectRoles } from '../models/my_project_roles.model';
import { ProjectTeam } from '../models/project_team.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private current_role:Role = null as any;
  private current_department_category:DepartmentCategory = null as any;
  private current_department:Department = null as any;

  private roles:BehaviorSubject<{[key:string]:Role}> = new BehaviorSubject(null as any);
  private departments:BehaviorSubject<{[key:string]:Department}> = new BehaviorSubject(null as any);
  private departmentCategories:BehaviorSubject<{[key:string]:DepartmentCategory}> = new BehaviorSubject(null as any);
  public rolesObservable:Observable<{[key:string]:Role}> = this.roles.asObservable();
  public departmentsObservable:Observable<{[key:string]:Department}> = this.departments.asObservable();
  public departmentCategoriesObservable:Observable<{[key:string]:DepartmentCategory}> = this.departmentCategories.asObservable();

  // initiating observables
  public fetchingRolesPromise:Promise<Role[]> = null as any;
  public fetchingDepartmentsPromise:Promise<ArrayResponse<Department>> = null as any;
  public fetchingDepartmentCategoriesPromise:Promise<DepartmentCategory[]> = null as any;
  public fetchingMyProjectRoles:Promise<MyProjectRoles> = null as any;

  // other..
  myProjectRoles:MyProjectRoles = null as any;

  my_project_teams:{[key:number]:ProjectTeam} = {};

  constructor(private userService:UserService,
              private api:ApiService,
              private utils:UtilsService) {
    this.initRolesAndDepartments();
    this.listenCurrentUser();
  }

  /**
   * OBSERVABLES METHODS
   */
  getCurrentDepartments() {
    return this.departments.value;
  }

  getCurrentRole() {
    return this.current_role;
  }

  /**
   * ROLES METHODS
   */
  isDepartment(department_slug:string, strict:boolean = false): boolean {
    if(this.departments.value==null) {
      return false;
    }
    else {
      const department_to_check = this.departments.value[department_slug];
      if(department_to_check!=null && this.current_department!=null) {
        if(strict) {
          return this.current_department.slug == department_to_check.slug;
        }
        else if(this.current_department.middleware_hierarchy == department_to_check.middleware_hierarchy) {
          return this.current_department.slug == department_to_check.slug;
        }
        else {
          return this.current_department.middleware_hierarchy < department_to_check.middleware_hierarchy; // més petit, jerarquia més alta
        }
      }
      else {
        return false;
      }
    }

  }

  isDepartmentCategory(departmentCategoryName:string): boolean {
    return this.current_department_category.name == departmentCategoryName;
  }

  canAccessVacationRequestPage() {
    if(this.current_department == null || this.current_role == null) return false;
    const valid_departments = ["direction", "rrhh"];
    return this.isValidDepartment(valid_departments);
  }

  validateDepartment(valid_departments:string[]) {
    if(this.current_role.id !== 1) return false;
    return this.isValidDepartment(valid_departments);
  }

  isMyDepartmentSet() {
    return this.current_department != null;
  }

  getMyProjectRoles() {
    return this.myProjectRoles;
  }

  isWorkerWithProjectRole(project_id: string, role:"project-manager"|"product-manager"|"developer"|"product-designer") {
    const user = this.userService.getCurrentUser();
    if(user.role.slug === "worker") {
      const project_user_member = this.myProjectRoles[+project_id];
      if(project_user_member == null) return false;

      return project_user_member.department_category_slug.indexOf(role) > -1;
    }
    else return false;
  }

  isClient() {
    const user = this.userService.getCurrentUser();
    return user.company_id != null && user.role.slug === "client";
  }

  isClientBusiness() {
    const user = this.userService.getCurrentUser();
    return user.company_id != null && user.role.slug === "client" && user.client_role === 'business';
  }

  isClientDeveloper() {
    const user = this.userService.getCurrentUser();
    return user.company_id != null && user.role.slug === "client" && user.client_role === 'developer';
  }

  isProductionTeam(project_id: string) {
    const user = this.userService.getCurrentUser();
    if(this.isWorker()) {
      return this.isEmployeeInProject(project_id);
    }
    else if(this.isClient()) {
      return this.isClientDeveloper();
    }
    else return false;
  }

  isEmployeeInProject(project_id: string) {
    return this.myProjectRoles!=null && project_id in this.myProjectRoles;
  }

  isWorker() {
    const user = this.userService.getCurrentUser();
    return user.role.slug === "worker";
  }

  isDeveloperInProjectTeam(project_team_id:number) {
    return this.my_project_teams[project_team_id] != null && (this.isWorker() || this.isClientDeveloper());
  }

  isProjectManager() {
    return this.current_department_category.slug?.startsWith('project-manager');
  }

  isProductManager() {
    return this.current_department_category.slug?.startsWith('product-manager');
  }

  /**
   * FUNCIONES PRIVADAS
   */
  private isValidDepartment(valid_departments:string[]): boolean {
    return this.current_department!=null ? valid_departments.indexOf(this.current_department.slug) > -1 : false;
  }

  private listenCurrentUser() {
    this.userService.currentUser.subscribe(
      data => {
        if(data != null) {
          this.current_role = data.role;
          if(this.current_role.slug === "worker") {
            this.current_department_category = data.current_working_contract.current_working_contract_variable_condition.department_category;
            // data.department.slug = "support"; // per forçar rol temporalment: ["direction", "finance", "support", "development", "sysadmin", "designers", "marketing"]
            //this.current_department = data.department;
            if(this.current_department_category != null) {
              this.api.getDepartment(data.current_working_contract.current_working_contract_variable_condition.department_category.department_id.toString()).subscribe(d => {
                this.current_department = d;
              });
            }
          }
          this.initMyProjectTeams();
        }
        else {
          this.current_role = null as any;
          this.current_department_category = null as any;
          this.current_department = null as any;
        }
      }
    );
  }

  private initRolesAndDepartments() {
    this.fetchingDepartmentsPromise = this.api.getDepartments().toPromise() as Promise<ArrayResponse<Department>>;
    this.fetchingDepartmentCategoriesPromise = this.api.getDepartmentCategories().toPromise() as Promise<DepartmentCategory[]>;
    this.fetchingRolesPromise = this.api.getRoles().toPromise() as Promise<Role[]>;

    const user = this.userService.getCurrentUser();
    if(user.role.slug === "worker") {
      this.fetchingMyProjectRoles = this.api.getMyProjectRoles(user.id).toPromise() as Promise<MyProjectRoles>;
    }

    this.fetchingDepartmentsPromise.then(
      data => {
        let obj:any = {};
        data.data.forEach(d => {
          obj[d.slug] = d;
        });
        this.departments.next(obj);
      }
    );

    this.fetchingRolesPromise.then(
      data => {
        let obj:any = {};
        data.forEach(r => {
          obj[r.slug] = r;
        });
        this.roles.next(obj);
      }
    );

    this.fetchingDepartmentCategoriesPromise.then(
      data => {
        let obj:any = {};
        data.forEach(r => {
          obj[r.name] = r;
        });
        this.departmentCategories.next(obj);
      }
    );

    if(this.fetchingMyProjectRoles != null) {
      this.fetchingMyProjectRoles.then(
        data => {
          this.myProjectRoles = data
        }
      )
    }
  }

  private initMyProjectTeams() {
    this.api.getMyProjectTeams().subscribe(
      data => {
        this.my_project_teams = {};
        data.forEach(pt => {
          this.my_project_teams[pt.id] = pt;
        });
      }
    );
  }
}
