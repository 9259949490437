import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTitleFormatter,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WeekViewHourSegment } from 'calendar-utils';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { WorkingTimesCalendarDialogComponent } from './working-times-calendar-dialog/working-times-calendar-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { WorkingContract } from 'src/app/models/working_contract.model';
import * as moment from 'moment';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { EmployeeCalendarRegisterWithRelationsSerialized } from 'src/app/models/employee_calendar_register.model';
import { CustomEventTitleFormatter } from '../../../shared/modules/custom-event-title-formatter.provider';
import { CalendarAssignmentDialogComponent } from '../../project-management/project-assignment/calendar-assignment-dialog/calendar-assignment-dialog.component';

@Component({
  selector: 'app-working-times-calendar',
  templateUrl: './working-times-calendar.component.html',
  styleUrls: ['./working-times-calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class WorkingTimesCalendarComponent implements OnInit {

  filterForm:UntypedFormGroup;

  private formPersistence:any;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate:Date = null as any;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  modalVisible: boolean = false;

  actions: CalendarEventAction[] = [];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  registers: EmployeeCalendarRegisterWithRelationsSerialized[];

  calendarUrl: string;

  active_contract:WorkingContract;

  absences:("holiday"|"vacation"|"other"|null)[];

  hour_segmented_height = 3;
  hour_segments = 24;

  constructor(private modal: NgbModal,
              private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private router:Router,
              private fb:UntypedFormBuilder,
              private utils:UtilsService,
              private userService:UserService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private elementRef: ElementRef) {
      this.filterForm = this.fb.group({
        from: [this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.getMonday(new Date())],
        to: [this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.getSunday(new Date())]
      });

      this.viewDate = this.filterForm.value['from'];

      this.initAbsencesAttribute();
    }

  ngOnInit() {
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.setStarterQueryParameters();
    this.fetchUserContract();

    this.calendarUrl = environment.laravel_url + "/api/v1/users/" + this.userService.getCurrentUser().id + "/calendar";
  }

  changeWeek() {
    this.filterForm.patchValue({
      from: this.getMonday(this.viewDate),
      to: this.getSunday(this.viewDate)
    });
  }

  getMonday(d: Date) {
    // adjust when day is monday
    return new Date(d.setDate(d.getDate() - d.getDay() + 1));
  }

  getSunday(d: Date) {
     // if day is sunday, take it as last day of the week
    if(d.getDay()===0) return d;
    return new Date(d.setDate(this.getMonday(d).getDate() + 6));
  }

  eventClicked(event:{event:CalendarEvent<any>; sourceEvent: MouseEvent|KeyboardEvent}) {
    const project_id = (event.event as any).project_id;
    if(project_id != null) {
      this.router.navigate(['/employees', 'development', 'projects', project_id]);
    }
  }

  fecthRemoteWorkingDays() {

    this.api.getEmployeeRemoteWork(this.userService.getCurrentUser().id.toString(), this.getMonday(new Date(this.filterForm.value["from"])), this.getMonday(new Date(this.filterForm.value["to"]))).subscribe(
      data => {
        data.forEach((item: any) => {
          // add date as event all day to calendar
          this.events.push({
            start: new Date(item),
            title: '🏠 Teletrabajo',
            color: {
              primary: 'transparent',
              secondary: 'transparent',
              secondaryText: '#828282'
            },
            allDay: true,
            meta: {
              hiddeTooltip: true
            }
          });
        });
      }
    );
  }

  private fetchUserContract() {
    this.active_contract = null as any;

    const params:any = {
      day: this.utils.dateToStringYYYYMMDD(this.filterForm.value['from']),
      with: 'current_working_contract_variable_condition'
    };

    this.api.getActiveWorkingContract(this.userService.getCurrentUser().id, params).subscribe(
      data => {
        this.active_contract = data;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="")  params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate(['/employees', 'my-zone', 'calendar'], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.viewDate = params.from != null ? this.utils.stringToDate(params.from) : null as any;
        this.setEvents();
      }
    );
  }

  private setStarterQueryParameters() {
    this.router.navigate(['/employees', 'my-zone', 'calendar'], {
      queryParams: {
        from: this.utils.dateToString(this.getMonday(new Date(this.filterForm.value["from"]))),
        to: this.utils.dateToString(this.getSunday(new Date(this.filterForm.value["to"])))
      }
    });
  }

  private setEvents() {
    this.events = [];

    const user_id = this.userService.getCurrentUser().id;
    if(user_id!=null) {
      this.api.getEmployeeCalendarRegistersForUserSerialized(user_id.toString(), this.filterForm.value['from'], this.filterForm.value['to']).subscribe(
        (data:EmployeeCalendarRegisterWithRelationsSerialized[]) => {
          this.registers = data;
          this.events = data.map((item: EmployeeCalendarRegisterWithRelationsSerialized) => {
            let colors = {
              primary: item.project_color ?? item.wtc_color ?? item.ecr_wtc_color ?? '#ad2121',
              secondary: item.project_color ?? item.wtc_color ?? item.ecr_wtc_color ?? '#FAE3E3',
            };
            return {
              id: item.id,
              start: new Date(item.from_date),
              end: new Date(item.to_date),
              title: item.project_title ?? item.wtc_name,
              hover: item.project_title + ' '+item.ecr_wtc_name ?? item.wtc_name + ' '+item.ecr_wtc_name,
              subtitle: item.ecr_wtc_name,
              project_phase_name: item.project_phase_name,
              project_id: item.working_category_type == "App\\Models\\WeeklyWorkingTimeBudgetEmployee" ? item.project_id : null,
              color: colors,
              actions: [],
              // height: this.calculateHeightOfEvent(this.calculateDifferenceInMinutes(new Date(item.from_date), new Date(item.to_date)) ?? 5),
            } as any;
          }
        );
        this.refreshEvents();
        this.fecthRemoteWorkingDays();
        this.fetchAbsences();
      });
    }
  }

  // private calculateDifferenceInMinutes(date1: Date, date2: Date): number {
  //   const differenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  //   const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
  //   return differenceInMinutes;
  // }

  // private calculateHeightOfEvent(minutes: number): number {
  //   return (minutes*this.hour_segments/60) * this.hour_segmented_height;
  // }

  openDialog(): void {
    this._snackBar.open('Enlace copiado!', 'Undo', {
      duration: 3000,
    });
    this.dialog.open(WorkingTimesCalendarDialogComponent, {
      width: '600px',
      data: {calendarUrl: this.calendarUrl}
    });
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    if(this.active_contract != null) {
      renderEvent.hourColumns.forEach((hourColumn) => {
        const dayIndex:number = hourColumn.date.getDay(); // 0: sunday, 1: monday
        const { morning_entry_time, morning_exit_time, afternoon_entry_time, afternoon_exit_time } = this.getDayRangesByIndex(dayIndex);
        if((morning_entry_time!=null && morning_exit_time!=null) || (afternoon_entry_time != null && afternoon_exit_time != null)) {
          hourColumn.hours.forEach((hour) => {
            hour.segments.forEach((segment) => {
              const hours = segment.date.getHours();
              const minutes = segment.date.getMinutes();
              const hours_ponderated = hours + (minutes===0 ? 0 : (minutes/60));

              const absence = this.absences[dayIndex];

              if(absence!=null) {
                segment.cssClass = absence;
              }
              else if (
                (hours_ponderated >= morning_entry_time && hours_ponderated < morning_exit_time) ||
                (hours_ponderated >= afternoon_entry_time && hours_ponderated < afternoon_exit_time)
              ) {
                segment.cssClass = 'contractable';
              }
            });
          });
        }
      });
    }
  }

  private fetchAbsences() {
    this.api.getEmployeeAbsences(this.userService.getCurrentUser().id, this.filterForm.value['from'], this.filterForm.value['to']).subscribe(
      data => {
        this.initAbsencesAttribute();

        // vacances
        data.employee_calendar_registers.forEach(ecr => {
          const index = moment(ecr.from_date).day();
          if(this.absences[index]==null) {
            let str:"other"|"vacation" = "other";
            if((ecr.working_category as WorkingTimeCategory).slug==="vacations") str = "vacation";
            this.absences[index] = str;
          }
        });

        // holidays
        data.holidays.forEach(h => {
          const index = moment(h.date).day();
          if(this.absences[index]==null) {
            this.absences[index] = "holiday";
          }
        });

        this.refreshEvents();
      }
    );
  }

  private refreshEvents() {
    this.refresh.next();
  }

  private getDayRangesByIndex(dayIndex:number) {
    switch(dayIndex) {
      case 0:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.sunday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.sunday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.sunday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.sunday_afternoon_exit_time
        };
      case 1:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.monday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.monday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.monday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.monday_afternoon_exit_time
        };
      case 2:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.tuesday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.tuesday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.tuesday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.tuesday_afternoon_exit_time
        };
      case 3:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.wednesday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.wednesday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.wednesday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.wednesday_afternoon_exit_time
        };
      case 4:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.thursday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.thursday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.thursday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.thursday_afternoon_exit_time
        };
      case 5:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.friday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.friday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.friday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.friday_afternoon_exit_time
        };
      case 6:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.saturday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.saturday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.saturday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.saturday_afternoon_exit_time
        };
      default:
        return {
          morning_entry_time: 0,
          morning_exit_time: 0,
          afternoon_entry_time: 0,
          afternoon_exit_time: 0
        };
    }
  }

  private initAbsencesAttribute() {
    this.absences = [null,null,null,null,null,null,null];
  }

  openCalendars() {
    const dialogRef = this.dialog.open(CalendarAssignmentDialogComponent, {
      width: '90%',
      disableClose: true,
      data: {
        from: this.filterForm.value['from'],
        to: this.filterForm.value['to']
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.setEvents();
    });
  }

}
