import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as moment from 'node_modules/moment';
import { WorkingTimeHistoryDayDetailDialogComponent } from './working-time-history-day-detail-dialog/working-time-history-day-detail-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AddNewWorkingTimeDialogComponent } from './add-new-working-time-dialog/add-new-working-time-dialog.component';
import { WorkingTime } from 'src/app/models/working_time.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { WorkingTimeSummary } from 'src/app/models/working-time-summary.model';

@Component({
  selector: 'app-working-times-history',
  templateUrl: './working-times-history.component.html',
  styleUrls: ['./working-times-history.component.css']
})
export class WorkingTimesHistoryComponent implements OnInit {

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  userControl:UntypedFormControl = new UntypedFormControl();
  userAutocompleteControl:UntypedFormControl = new UntypedFormControl();
  usersFiltered:User[] = [];
  titleRowSpan:number = 1;
  private user_id:number = null as any;
  expectedHours:any = null;

  summary:WorkingTimeSummary;
  mainDisplayedColumnKeysDefault:string[] = ['category', 'sub_category'];
  mainDisplayedColumnKeys:string[] = this.mainDisplayedColumnKeysDefault.map(f => f);
  mainDataSource:MatTableDataSource<WorkingTimeCategoryLine> = new MatTableDataSource<WorkingTimeCategoryLine>();

  bisual_user = null as any;

  constructor(private api:ApiService,
              private user:UserService,
              private router:Router,
              private utils:UtilsService,
              private activatedRoute:ActivatedRoute,
              private fb:UntypedFormBuilder,
              private dialog:MatDialog,
              private permissions:PermissionsService) {

    this.filterForm = this.fb.group({
      user_id: [this.activatedRoute.snapshot.queryParamMap.get('user_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('user_id') as any) : '', Validators.required],
      from: [this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.getInitOfWeekDate(), Validators.required],
      to: [this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.getEndOfWeekDate(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.listenUserControl();
    this.listenUserAutocompleteControl();
    this.listenUserService();

    if(this.filterForm.value['user_id']!='') this.fetchUser(this.filterForm.value['user_id']);
    else this.initCurrentUser(); // aixo es el que ho inicia tot
  }

  createColumnKeyByDate(d:Date) {
    return moment(d).format('ddd-DD').toLowerCase();
  }

  getDayColumnHeader(d:Date) {
    return moment(d).format('ddd DD');
  }

  openWorklogResume(worklogs:WorkingTime[]) {
    const dialogRef = this.dialog.open(WorkingTimeHistoryDayDetailDialogComponent, {
      width: '650px',
      data: {
        worklogs: worklogs
      }
    });

    dialogRef.afterClosed().toPromise().then(
      data => {
        this.fetchWorkingTimeSummary();
      }
    );
  }

  changeWeek(num:number) {
    const old_from:Date = this.filterForm.value['from'];
    const new_from:Date = new Date(new Date(old_from).setDate(old_from.getDate() + num*7));
    const new_to:Date = new Date(new Date(new_from).setDate(new_from.getDate() + 6));

    this.filterForm.patchValue({
      from: new_from,
      to: new_to
    });
  }

  onUserAutocompleteSelected(event:MatAutocompleteSelectedEvent) {
    this.selectUser(event.option.value);
  }

  selectUser(user:User) {
    this.userControl.setValue(user);
  }

  displayFnUser(user:User): string {
    return user ? user.name + ' ' + user.surnames : '';
  }

  add() {
    const dialogRef = this.dialog.open(AddNewWorkingTimeDialogComponent, {
      width: '650px',
      data: { }
    });

    dialogRef.afterClosed().toPromise().then(
      (data:WorkingTime) => {
        if(data!=null) {
          if(this.filterForm.value['user_id']!=null) data.user_id = this.filterForm.value['user_id'];
          this.api.createWorkingTime(data).toPromise().then(
            data => {
              this.fetchWorkingTimeSummary();
            }
          );
        }
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="") params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate(['/employees', 'my-zone', 'history'], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
            else if(params_temp[param_key]!=null && (params_temp[param_key] as string).indexOf("-")>-1) params_temp[param_key] = this.utils.stringToDate(params_temp[param_key]);
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchWorkingTimeSummary();
      }
    );
  }

  private fetchWorkingTimeSummary() {
    const user_id = this.filterForm.value['user_id'];
    if(user_id!=null && user_id!='') {
      this.api.getWorkingTimeSummary(user_id, this.filterForm.value['from'], this.filterForm.value['to']).toPromise().then(
        data => {
          if(data != undefined) {
            this.summary = data;
            this.initTable(data);
            this.initColumns(data);
          }
        }
      );
    }
  }

  private initCurrentUser() {
    this.user.currentUser.subscribe(
      data => {
        if(this.filterForm.value['user_id']==null || this.filterForm.value['user_id']=='') {
          this.selectUser(data);
          this.usersFiltered = [data];
          this.userAutocompleteControl.setValue(data);
        }
      }
    );
  }

  private getInitOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private getEndOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() + 7 - day; // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private listenUserControl() {
    this.userControl.valueChanges.subscribe(
      user => {
        if(this.user_id==null || this.user_id!=user.id) {
          this.user_id = user.id;
          this.userAutocompleteControl.setValue(user);
          this.filterForm.patchValue({user_id: user.id});

          if(!this.permissions.validateDepartment(['direction', 'rrhh']) && !this.permissions.isProjectManager() && !this.permissions.isProductManager()) {
            this.userAutocompleteControl.disable();
            this.userControl.disable();
          }
        }
      }
    );
  }

  private listenUserAutocompleteControl() {
    this.userAutocompleteControl.valueChanges.subscribe(
      data => {
        if(data.id==null) {
          const params:any = {search: data, from: moment(this.filterForm.value['from']).format('YYYY-MM-DD'), to: moment(this.filterForm.value['to']).format('YYYY-MM-DD') };
          this.api.getActiveEmployees(params).toPromise().then(
            users => {
              if(users != undefined) this.usersFiltered = users;
            }
          );
        }
      }
    );
  }

  private fetchUser(id:string) {
    this.api.getUser(id).toPromise().then(
      data => {
        if(data != undefined) {
          this.selectUser(data);
          this.usersFiltered = [data];
          this.userAutocompleteControl.setValue(data);
        }
      }
    );
  }

  private listenUserService() {
    this.bisual_user = this.user.getCurrentUser();
  }

  private initTable(data:WorkingTimeSummary) {
    let res:WorkingTimeCategoryLine[] = [];

    data.detail.forEach((c, i) => {
      if(c.type==="project") {
        Object.keys(c.categories).forEach((k, i2) => {
          res.push({
            flag: c.flag,
            type: c.type,
            primary_entity_id: c.entity_id,
            primary_entity_title: c.entity_title,
            secondary_entity_id: c.categories[k].project_sub_category_id,
            secondary_entity_title: c.categories[k].project_sub_category_name ?? c.categories[k].name,
            days: c.categories[k].days?.map((item, i) => { (item as any).flag = c.days[i].flag; return item as any; }),
            total_seconds_done: c.total_seconds_done,
            total_seconds_expected: c.total_seconds_expected,
            is_first_subcategory: i2===0,
            secondary_items: Object.keys(c.categories).length,
            level: 1,
            total_seconds_subcat_done: c.categories[k].total_seconds_done,
            total_seconds_subcat_expected: c.categories[k].total_seconds_expected, // .days?.map(item => item.total_seconds_expected).reduce((prev, curr) => { if(!prev) prev = 0; return prev + curr; }),
            deep: c.categories[k].deep,
            // parent deep is parent deep + the number of brothers categories
            parent_deep: null,
            // count of categories
            categories: c.categories[k] != null ? Object.keys(c.categories[k].categories).length : null,
            // pass categories to an array, but also pass the categories of categories to an array, its possible to k2 and k3 be null
            // categories: Object.keys(c.categories[k].categories).map((k2, i3) => { return { project_sub_category_id: c.categories[k].categories[k2].project_sub_category_id, project_sub_category_name: c.categories[k].categories[k2].project_sub_category_name, categories: Object.keys(c.categories[k].categories[k2].categories).map((k3, i4) => { return { project_sub_category_id: c.categories[k].categories[k2].categories[k3].project_sub_category_id, project_sub_category_name: c.categories[k].categories[k2].categories[k3].project_sub_category_name }; }) }; })
          });
          // push all subcategories also in res
          Object.keys(c.categories[k].categories).forEach((k2, i3) => {
            res.push({
              flag: c.flag,
              type: c.type,
              primary_entity_id: c.entity_id,
              primary_entity_title: c.entity_title,
              secondary_entity_id: c.categories[k].categories[k2].project_sub_category_id,
              secondary_entity_title: c.categories[k].categories[k2].project_sub_category_name ?? c.categories[k].categories[k2].name,
              days: c.categories[k].categories[k2].days?.map((item, i) => { (item as any).flag = c.days[i].flag; return item as any; }),
              total_seconds_done: c.total_seconds_done,
              total_seconds_expected: c.total_seconds_expected,
              is_first_subcategory: false,
              level: 2,
              secondary_items: Object.keys(c.categories[k].categories[k2].categories).length,
              deep: c.categories[k].categories[k2].deep,
              parent_deep: null,
              categories: c.categories[k].categories[k2] != null ? Object.keys(c.categories[k].categories[k2].categories).length : null,
              total_seconds_subcat_done: c.categories[k].categories[k2].total_seconds_done,
              total_seconds_subcat_expected: c.categories[k].categories[k2].total_seconds_expected, // .days?.map(item => item.total_seconds_expected).reduce((prev, curr) => { if(!prev) prev = 0; return prev + curr; }),
            });

            // push all subcategories also in res
            Object.keys(c.categories[k].categories[k2].categories).forEach((k3, i4) => {
              res.push({
                flag: c.flag,
                type: c.type,
                primary_entity_id: c.entity_id,
                primary_entity_title: c.entity_title,
                secondary_entity_id: c.categories[k].categories[k2].categories[k3].project_sub_category_id,
                secondary_entity_title: c.categories[k].categories[k2].categories[k3].project_sub_category_name ?? c.categories[k].categories[k2].categories[k3].name,
                days: c.categories[k].categories[k2].categories[k3].days?.map((item, i) => { (item as any).flag = c.days[i].flag; return item as any; }),
                total_seconds_done: c.total_seconds_done,
                total_seconds_expected: c.total_seconds_expected,
                is_first_subcategory: false,
                level: 3,
                secondary_items: Object.keys(c.categories[k].categories[k2].categories[k3].categories).length,
                deep: c.categories[k].categories[k2].categories[k3].deep,
                parent_deep: null,
                categories: c.categories[k].categories[k2].categories[k3] != null ? Object.keys(c.categories[k].categories[k2].categories[k3].categories).length : null,
                total_seconds_subcat_done: c.categories[k].categories[k2].categories[k3].total_seconds_done,
                total_seconds_subcat_expected: c.categories[k].categories[k2].categories[k3].total_seconds_expected, // .days?.map(item => item.total_seconds_expected).reduce((prev, curr) => { if(!prev) prev = 0; return prev + curr; }),
              });

              // push all subcategories also in res
              Object.keys(c.categories[k].categories[k2].categories[k3].categories).forEach((k4, i5) => {
                res.push({
                  flag: c.flag,
                  type: c.type,
                  primary_entity_id: c.entity_id,
                  primary_entity_title: c.entity_title,
                  secondary_entity_id: c.categories[k].categories[k2].categories[k3].categories[k4].project_sub_category_id,
                  secondary_entity_title: c.categories[k].categories[k2].categories[k3].categories[k4].project_sub_category_name ?? c.categories[k].categories[k2].categories[k3].categories[k4].name,
                  days: c.categories[k].categories[k2].categories[k3].categories[k4].days?.map((item, i) => { (item as any).flag = c.days[i].flag; return item as any; }),
                  total_seconds_done: c.total_seconds_done,
                  total_seconds_expected: c.total_seconds_expected,
                  is_first_subcategory: false,
                  level: 4,
                  secondary_items: Object.keys(c.categories[k].categories[k2].categories[k3].categories[k4].categories).length,
                  deep: c.categories[k].categories[k2].categories[k3].categories[k4].deep,
                  parent_deep: null,
                  categories: c.categories[k].categories[k2].categories[k3].categories[k4] != null ? Object.keys(c.categories[k].categories[k2].categories[k3].categories[k4].categories).length : null,
                  total_seconds_subcat_done: c.categories[k].categories[k2].categories[k3].categories[k4].total_seconds_done,
                  total_seconds_subcat_expected: c.categories[k].categories[k2].categories[k3].categories[k4].total_seconds_expected, // .days?.map(item => item.total_seconds_expected).reduce((prev, curr) => { if(!prev) prev = 0; return prev + curr; }),
                });
              });
            });
          });
          // parent_deep = count of zsame primary_entity_id
          let max_deep = 0;
          res.forEach((item, i) => {
            const parent_deep = res.filter(item2 => item2.primary_entity_id===item.primary_entity_id).length;
            item.parent_deep = parent_deep;
            if(item.deep!=null) max_deep = Math.max(max_deep, item.deep);
          });

          this.titleRowSpan = Math.max(this.titleRowSpan, max_deep);
        });
      } else if(c.type === "category") {
        res.push({
          flag: c.flag,
          type: c.type,
          primary_entity_id: c.entity_id,
          primary_entity_title: c.entity_title,
          days: c.days?.map(item => { return { total_seconds_done: item.total_seconds_done, worklogs: item.worklogs, flag: item.flag }; }),
          total_seconds_done: c.total_seconds_done,
          total_seconds_expected: c.total_seconds_expected,
          is_first_subcategory: true,
          secondary_items: 1,
          total_seconds_subcat_done: 0,
          total_seconds_subcat_expected: 0,
        } as any);
      }
    });
    this.mainDataSource.data = res;
  }

  private initColumns(data:WorkingTimeSummary) {
    this.mainDisplayedColumnKeys = this.mainDisplayedColumnKeysDefault.map(f => f);
    data.days.forEach(day => {
      this.mainDisplayedColumnKeys.push(this.createColumnKeyByDate(day));
    });

    this.mainDisplayedColumnKeys.push('total_project_category');
    this.mainDisplayedColumnKeys.push('total_project');
  }

}

interface WorkingTimeCategoryLine {
  type:string;
  primary_entity_id:number;
  primary_entity_title:string;
  secondary_entity_id:number|null;
  secondary_entity_title:string|null;
  days:undefined|{total:number;worklogs:WorkingTime[];flag:string|null}[];
  total_seconds_done:number;
  total_seconds_expected:number;
  total_seconds_subcat_done:number;
  total_seconds_subcat_expected:number;
  is_first_subcategory:boolean;
  secondary_items:number;
  categories:number|null;
  deep:number|null;
  level:number|null;
  parent_deep:number|null;
  flag:string;
}
