<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Historial de trabajo</h1>

          <!-- aqui aniran els controls -->
          <mat-form-field appearance="outline" class="nobottom">
            <span matPrefix><mat-icon>person</mat-icon></span>
            <input [formControl]="userAutocompleteControl" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnUser" (optionSelected)="onUserAutocompleteSelected($event)">
              @for (user of usersFiltered; track user) {
                <mat-option [value]="user">{{user.name + ' ' + user.surnames}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

          <div class="date-controller card">
            <button mat-icon-button (click)="changeWeek(-1)"><mat-icon>chevron_left</mat-icon></button>
            <span class="date">{{ filterForm.value['from'] | date:'shortDate' }} - {{ filterForm.value['to'] | date:'shortDate' }}</span>
            <button mat-icon-button (click)="changeWeek(1)"><mat-icon>chevron_right</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if (summary != null) {
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="bisual-table-container">
            <table mat-table [dataSource]="mainDataSource">
              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                  @if (bisual_user.id == filterForm.value.user_id) {
                    <button mat-flat-button color="accent" (click)="add()">
                      <mat-icon sytle="margin-right: 10px">add</mat-icon>
                      Registrar tiempo trabajado
                    </button>
                  }
                </th>
                <ng-container mat-cell *matCellDef="let line; let i = index;">
                  @if (line.type=='project') {
                    @if (line.is_first_subcategory) {
                      <td mat-cell [attr.rowspan]="line.parent_deep" style="min-width: 150px; max-width: 200px">
                        <a [routerLink]="['/employees', 'development', 'projects', line.primary_entity_id]">{{ line.primary_entity_title }}</a>
                      </td>
                    }
                  }
                  @if (line.type=='category') {
                    <td mat-cell [attr.colspan]="2">{{ line.primary_entity_title }}</td>
                  }
                </ng-container>
                <td mat-footer-cell *matFooterCellDef><b>Resumen</b></td>
              </ng-container>
              <ng-container matColumnDef="sub_category">
                <ng-container mat-header-cell *matHeaderCellDef></ng-container>
                <ng-container mat-cell *matCellDef="let line; let i = index;">
                  @if (line.type=='project') {
                    <td mat-cell [ngClass]="{'nopadding': true}" style="min-width: 150px;">
                      <ng-container>
                        @for (i of [].constructor(line.level); track i) {
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        }
                        {{ line.secondary_entity_title }}
                      </ng-container>
                    </td>
                  }
                </ng-container>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              @for (day of summary.days; track day; let i = $index) {
                <ng-container [matColumnDef]="createColumnKeyByDate(day)" >
                  <th mat-header-cell *matHeaderCellDef style="min-width: 80px">{{ getDayColumnHeader(day) }}</th>
                  <td mat-cell *matCellDef="let line;" >
                    @if(line.days != null) {
                      <span
                        (click)="line.days[i].worklogs.length>0 ? openWorklogResume(line.days[i].worklogs) : null"
                        [ngClass]="{'worklog-day-clickable': line.days[i].worklogs.length>0, 'warn': line.days[i].flag==='warn', 'error': line.days[i].flag==='error'}"
                        [matTooltip]="(line.days[i].worklogs.length>0) ? 'Ver detalle de worklogs' : ''">
                        {{ line.days[i].total_seconds_done | secondsToStringTime }}
                      </span>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    <b [ngClass]="summary.resume_days_in_seconds[i].flag ?? ''">{{ summary.resume_days_in_seconds[i].done | secondsToStringTime }}</b>
                    /
                    {{ summary.resume_days_in_seconds[i].expected | secondsToStringTime }}
                  </td>
                </ng-container>
              }
              <ng-container matColumnDef="total_project_category">
                <th mat-header-cell *matHeaderCellDef style="padding: 0; min-width: 110px;">Total horas</th>
                <td mat-cell *matCellDef="let line; let i = index;">
                  <span
                    [ngClass]="line.total_seconds_subcat_expected > 0 ? line.flag : null">
                    {{ line.total_seconds_subcat_done | secondsToStringTime }}
                    @if(line.total_seconds_subcat_expected > 0) {
                      / {{ line.total_seconds_subcat_expected | secondsToStringTime }}
                    }
                  </span>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="total_project">
                <th mat-header-cell *matHeaderCellDef style="padding: 0; min-width: 110px;"></th>
                <ng-container mat-cell *matCellDef="let line; let i = index;">
                  @if (line.is_first_subcategory) {
                    <td mat-cell [attr.rowspan]="line.parent_deep">
                      <b [ngClass]="summary.resume_categories_in_seconds[line.type==='project' ? 'projects' : 'categories'][line.primary_entity_id].flag ?? ''">
                        {{ summary.resume_categories_in_seconds[line.type==='project' ? 'projects' : 'categories'][line.primary_entity_id].done | secondsToStringTime }}
                      </b>
                      /
                      {{ summary.resume_categories_in_seconds[line.type==='project' ? 'projects' : 'categories'][line.primary_entity_id].expected | secondsToStringTime }}
                    </td>
                  }
                </ng-container>
                <td mat-footer-cell *matFooterCellDef style="padding: 0px 10px !important; text-align: center;">
                  <b [ngClass]="summary.resume.flag ?? ''">
                    {{ summary.resume.done | secondsToStringTime }} / {{ summary.resume.expected | secondsToStringTime }}
                  </b>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="mainDisplayedColumnKeys"></tr>
              <tr mat-row rowspan="2" *matRowDef="let cat; let i = index; columns: mainDisplayedColumnKeys;"></tr>
              <tr mat-footer-row *matFooterRowDef="mainDisplayedColumnKeys"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>
