import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { ProjectTask } from 'src/app/models/project_task.model';
import { ProjectPhaseBoardCol } from 'src/app/models/project_phase_board_col.model';
import { User } from 'src/app/models/user.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SecondsToStringTimePipe } from 'src/app/pipes/seconds-to-string-time.pipe';
import { MatSelect } from '@angular/material/select';
import { CdkDrag, CdkDragDrop, CdkDropList, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Router, Event as RouterEvent} from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditProjectPhaseBoardDialogComponent } from '../project-phase-boards/edit-project-phase-board-dialog/edit-project-phase-board-dialog.component';
import { CompleteProjectPhaseBoardDialogComponent } from '../project-phase-boards/complete-project-phase-board-dialog/complete-project-phase-board-dialog.component';
import { DatePipe } from '@angular/common';
import { ProjectTaskDetailComponent } from '../../../../project-task-detail/project-task-detail.component';
import { ImportFileDialogComponent } from 'src/app/components/utils/import-file-dialog/import-file-dialog.component';
import { UtilsService } from 'src/app/services/utils.service';
import { HourStringToSecondsPipe } from 'src/app/pipes/hour-string-to-seconds.pipe';
import { UserService } from 'src/app/services/user.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Project } from 'src/app/models/project.model';
import { ProjectTaskBoard } from 'src/app/models/project_task_board.model';
import { ProjectTaskTimeBudget } from 'src/app/models/project_task_time_budget.model';
import { ProjectPhaseBoardsComponent } from '../project-phase-boards/project-phase-boards.component';
import { ProjectPhase } from 'src/app/models/project_phase.model';

@Component({
  selector: 'app-project-phase-backlog',
  templateUrl: './project-phase-backlog.component.html',
  styleUrls: ['./project-phase-backlog.component.css']
})
export class ProjectPhaseBacklogComponent implements OnInit, OnDestroy {

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private secondsToStringTime: SecondsToStringTimePipe,
    private utils: UtilsService,
    private dialog:MatDialog,
    private userService:UserService,
    private router: Router,
    public permissions:PermissionsService
  ) {
    this.canDropTasks = this.canDropTasks.bind(this);
  }

  // Variables

  private phaseId: string;
  public projectId: string;
  private lastSelectedId: string;
  createTaskBoardId : string | number |null;
  creatingTask: boolean = false;
  selectNewTaskType: boolean = false;
  boards: ProjectPhaseBoardForList[] = [];
  users: User[] = [];
  editingTitleTaskId: string | null;
  editingTypeTaskId: string | null;
  newTaskTitle: string;
  selectedItems: SelectedItem[] = [];
  selecting: boolean = false;
  selectingBoardId: string | number | null;
  creatingTaskType: string = "story";
  creatingNewTaskTitle: string = "";
  csvSeparator: string = ";";
  dialogAlreadyOpened: boolean = false;
  isProjectManagerOrProductManager:boolean = false;
  isProductManager:boolean = false;
  isWorker:boolean = false;
  isCompanyBusinessUser:boolean = false;
  isCompanyDeveloperUser:boolean = false;
  project:Project = null as any;
  project_phase:ProjectPhase = null as any;
  get canAcceptTasks() { return this.isProductManager || this.isCompanyBusinessUser }
  me:User|undefined;

  private show_archived_tasks:boolean = false;
  private show_archived_boards:boolean = false;

  private selectingOutListener = (e:Event) => {
    const self = this;
    self.documentClickListener(e, self);
  };

  // Init

  ngOnInit(): void {
    this.phaseId = this.activatedRoute.parent?.snapshot.paramMap.get('phase_id') as string;
    this.projectId = this.activatedRoute.parent?.snapshot.paramMap.get('project_id') as string ?? this.activatedRoute.parent?.parent?.snapshot.paramMap.get('project_id') as string;
    this.me = this.userService.getCurrentUser();
    this.fetchProject();
    this.fetchProjectPhase();
    this.fetchProjectPhaseBoards();
    this.fetchActiveUsers();
    this.listenQueryParameters();
    this.initIsPMorPO();
  }

  getId(task: ProjectTask|ProjectTaskBoard) {
    if((task as ProjectTask).title != null) return `project_task_${task.id}`;
    else return `project_task_board_${task.id}`;
  }

  isArchived(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    return t.is_archived;
  }

  getTaskType(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    return t.type;
  }

  getTaskCode(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    return t.code;
  }

  getTaskTitle(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    return t.title;
  }

  getTaskPriority(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    return t.priority;
  }

  isProjectTask(task: ProjectTask|ProjectTaskBoard) {
    return (task as ProjectTask).title != null;
  }

  getTaskColType(task: ProjectTask|ProjectTaskBoard) {
    if((task as ProjectTask).title != null) return null;
    else return (task as ProjectTaskBoard).project_phase_board_col.type;
  }

  getTaskColId(task: ProjectTask|ProjectTaskBoard) {
    if((task as ProjectTask).title != null) return null;
    else return (task as ProjectTaskBoard).project_phase_board_col.id;
  }

  getProjectTaskBoard(task:ProjectTask|ProjectTaskBoard) {
    return task as ProjectTaskBoard;
  }

  getProjectTask(task:ProjectTask|ProjectTaskBoard) {
    return (task as ProjectTask).project_phase_id != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(params.show_archived_tasks != null && (params.show_archived_tasks == 'true') != this.show_archived_tasks) {
          this.show_archived_tasks = params.show_archived_tasks == 'true';
          this.fetchProjectPhaseBoards();
        }
        if(params.show_archived_boards != null && (params.show_archived_boards == 'true') != this.show_archived_boards) {
          this.show_archived_boards = params.show_archived_boards == 'true';
          this.fetchProjectPhaseBoards();
        }
      }
    );
  }

  private checkOpenedTaskInParams() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if(queryParams.get('selectedIssue')!=null) {
        const selectedIssue = queryParams.get('selectedIssue') as string;
        this.openTask(selectedIssue, 'backlog');
      }
    });
  }

  ngOnDestroy(): void {
    this.removeSelection();
  }

  /* View childs focus */

  @ViewChild('editTaskTimeInput', { static: false })
  set editTaskTimeInput(element: ElementRef<HTMLInputElement>) {
    if(element) {
      element.nativeElement.focus()
    }
  }

  @ViewChild('editTaskTitleInput', { static: false })
  set editTaskTitleInput(element: ElementRef<HTMLInputElement>) {
    if(element) {
      element.nativeElement.focus()
    }
  }

  @ViewChild('editTaskAssigneeSelect', { static: false })
  set editTaskAssigneeSelect(element: MatSelect) {
    if(element) {
      element.open();
      element.focus();
    }
  }

  @ViewChild('editTypeTaskSelect', { static: false })
  set editTypeTaskSelect(element: MatSelect) {
    if(element) {
      element.open();
      element.focus();
    }
  }

  /* Public functions */
  startEditingTimeEstimation(task: ProjectTask|ProjectTaskBoard) {
    // abrir popup
  }

  canDropTasks(item: CdkDrag, list: CdkDropList): boolean {
    const origin_container_id = item.dropContainer.id;
    const destination_container_id = list.id;

    if(destination_container_id !== "for-approval") {

      if(
        (!isNaN(+origin_container_id) && !isNaN(+destination_container_id)) || // podem moure-ho entre taulers reals
        (origin_container_id === "backlog" && (!isNaN(+destination_container_id) || destination_container_id === "backlog")) // si el movem de backlog a (backlog o tauler) també ok...
      ) {
        const origin_container:ProjectPhaseBoardForList = this.boards.find(cont => cont.id === origin_container_id) as ProjectPhaseBoardForList;
        const destination_container:ProjectPhaseBoardForList = this.boards.find(cont => cont.id === destination_container_id) as ProjectPhaseBoardForList;

        return this.isProjectManagerOrProductManager || (
          this.isCompanyDeveloperUser && destination_container.can_client_dev_manage_board && (
            // pot moure de backlog a un tauler amb permissos
            (origin_container.id === "backlog" && !isNaN(+destination_container_id) && destination_container.can_client_dev_manage_board) ||
            // pot moure de tauler amb permissos al mateix tauler
            (origin_container.id === destination_container.id)
          )
        );
      }
      else return false;
    }
    else return false;
  }

  startEditingTitle(task: ProjectTask|ProjectTaskBoard) {
    this.editingTitleTaskId = task.frontendId;
    this.newTaskTitle = (task as ProjectTask).title != null ? (task as ProjectTask).title : (task as ProjectTaskBoard).project_task.title;
  }

  editTaskTitle(task: ProjectTask|ProjectTaskBoard) {
    this.editingTitleTaskId = null;
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    t.title = this.newTaskTitle;
    this.updateTask(t);
    this.stopEditingTaskTitle();
  }

  stopEditingTaskTitle() {
    this.editingTitleTaskId = null;
  }

  startChangeTaskType(task: ProjectTask|ProjectTaskBoard, board:ProjectPhaseBoardForList) {
    if(this.isProjectManagerOrProductManager || (this.isCompanyDeveloperUser && board.can_client_dev_manage_board)) {
      this.editingTypeTaskId = task.frontendId;
    }
  }

  changeTaskType(task: ProjectTask|ProjectTaskBoard, val:string) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    t.type = val;
    this.stopChangeTaskType();
    this.updateTask(t); // fem update task perque estem canviant un atribut a nivell de task
  }

  stopChangeTaskType() {
    this.editingTypeTaskId = null;
  }

  editTask(task: ProjectTask|ProjectTaskBoard){
    this.updateTask((task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task);
  }

  deleteTask(task: ProjectTask|ProjectTaskBoard, board: ProjectPhaseBoardForList) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    if(confirm(`¿Estás seguro que quieres eliminar la tarea "${t.title}"?`)) {
      this.removeSelection();
      this.api.deleteTask(t.id).subscribe();
      this.deleteTaskFromList(t, board);
    }
  }

  removeFromBoard(task:ProjectTask|ProjectTaskBoard, board: ProjectPhaseBoardForList) {
    const project_task_board = task as ProjectTaskBoard;
    if(confirm(`¿Estás seguro que quieres eliminar la tarea "${project_task_board.project_task.title}" del board "${board.title}"?`)) {
      this.removeSelection();
      this.api.deleteProjectTaskBoard(project_task_board.id).subscribe(
        data => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  deleteMultiple(board: ProjectPhaseBoardForList){
    if(confirm("Estás a punto de eliminar "+ this.selectedItems.length +  (this.selectedItems.length > 1 ? " tareas" : " tarea") )){
      this.deleteSelectedProjectTasks();
      this.removeSelection();
    }
  }

  dropTask(event: CdkDragDrop<(ProjectTask|ProjectTaskBoard)[]>) {
    if(event.previousContainer.id === "for-approval" || event.container.id === "for-approval") return; // no ha de poder moure res en drag n drop

    if (event.previousContainer === event.container) {
      // s'actualitza order_column, mateix board o backlog
      if(event.previousContainer.id === 'backlog' && event.container.id === 'backlog') {
        moveItemInArray<ProjectTask|ProjectTaskBoard>(event.container.data, event.previousIndex, event.currentIndex);
        this.changePhaseTasksOrder(event.container);
      }
      else {
        // NO S'HA DE PODER MOURE NI ACTUALITZAR ORDER_COLUMN DES DELS BOARDS
      }
    }
    else {
      const tasksToMove:(ProjectTask|ProjectTaskBoard)[] = this.selectedItems.length ? this.selectedItems.map(item => item.task) : [ event.previousContainer.data[event.previousIndex] ];
      if(event.previousContainer.id === "backlog" && !isNaN(+event.container.id)) {
        // si estem movent del backlog al board...
        (tasksToMove as ProjectTask[]).forEach(task => {
          this.moveProjectTaskFromBacklogToBoardColumn(task, event);
        });
      }
      else if(!isNaN(+event.previousContainer.id) && !isNaN(+event.container.id)) {
        // si estem movent entre boards...
        (tasksToMove as ProjectTaskBoard[]).forEach(task => {
          this.copyTaskFromBoardToBoard(task, event);
        });
      }
    }

    this.selectedItems = [];
  }

  private moveProjectTaskFromBacklogToBoardColumn(task: ProjectTask, event: CdkDragDrop<(ProjectTask|ProjectTaskBoard)[]>) {
    transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
    this.api.addToBoard(task.id, +event.container.id).subscribe(
      data => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  private copyTaskFromBoardToBoard(task: ProjectTaskBoard, event: CdkDragDrop<(ProjectTask|ProjectTaskBoard)[]>) {
    copyArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
    this.api.addToBoard(task.project_task_id, +event.container.id).subscribe(
      data => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  selectDetector(e : Event, board: ProjectPhaseBoardForList, task: ProjectTask|ProjectTaskBoard){
    if(!this.selecting){
      this.selecting = true;
      this.initSelection();
    }
    let event = e as PointerEvent;
    let metaKey = event.metaKey,
      shiftKey = event.shiftKey,
      ctrlKey = event.ctrlKey;

    let selectedBoard: ProjectPhaseBoardForList;

    if(this.selectingBoardId){
      selectedBoard = this.boards.find(board => board.id == this.selectingBoardId) ?? board;
    } else {
      selectedBoard = board;
    }

    if (shiftKey && this.lastSelectedId != null) {
      this.shiftSelect(board, task);
    } else if(metaKey || ctrlKey) {
      this.ctrlSelect(selectedBoard, task);
    } else {
      this.soloSelect(board, task);
    }
  }

  createTask(board: ProjectPhaseBoardForList, event: any = null) {
    event?.preventDefault();
    this.createTaskFromTitle(board, this.creatingNewTaskTitle);
    this.creatingNewTaskTitle = "";
  }

  createMultipleTasksFromClipboard(event: ClipboardEvent, board: ProjectPhaseBoardForList) {
    event.preventDefault();
    navigator['clipboard'].readText().then((data) => {
      let titles = data.split(/^/gm);
      titles = titles.filter(title => title.trim().length > 0).map(title => title.replace('* ', '').replace('*', '').replace('- ', '').replace('-', ''));
      if(titles.length > 1) {
        if(!confirm("¿Quieres crear " + titles.length + " tareas?")) {
          return;
        }
      }

      let tasks: any = [];
      titles.forEach(title => {
        tasks.push(this.generateTaskFromTitle(board, title, false));
      });

      this.importTasksBulk(tasks, board);
      this.creatingNewTaskTitle = "";
    });
  }

  generateTaskFromTitle(board: ProjectPhaseBoardForList, title: string, can_be_draft:boolean = true): any {
    return {
      title: title,
      project_phase_board_col_id: typeof board.id === 'string' ? null : (board.project_phase_board_cols ? board.project_phase_board_cols.find(col => col.project_phase_board_col_role.slug === "todo")?.id ??  board.project_phase_board_cols[0].id : null),
      type: this.creatingTaskType,
      priority: 'medium',
      project_phase_id: board.project_phase_id ?? null,
      requires_client_approval: board.id === "for-approval",
      is_draft: can_be_draft && board.id === 'for-approval'
    }
  }

  createTaskFromTitle(board: ProjectPhaseBoardForList, title: string) {
    if(title.length > 2) {
      const task = this.generateTaskFromTitle(board, title, true);

      this.api.createTask(task).subscribe(
        data => {
          board.tasks.push(data);
          this.stopCreatingTask();
        }
      );
    }
  }


  /* Creating new task */

  startSelectingNewTaskType(){
    this.selectNewTaskType = true;
  }

  startCreatingTask(board_id: number | string | null){
    this.createTaskBoardId = board_id;
    this.creatingTask = true;
  }

  stopCreatingTask(event: any = null){
    event?.preventDefault();
    this.createTaskBoardId = null;
    this.creatingTask = false;
    this.creatingTaskType = "story";
    this.creatingNewTaskTitle = "";
  }

  requireClientApproveTask(task:ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    if(confirm("¿Estás seguro que quieres solicitar aprobación de la tarea?")) {
      this.api.requireClientApproveTask(t.id).subscribe(
        data => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  approveTask(task:ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    if(confirm("¿Estás seguro que quieres aprobar la tarea y moverla al backlog?")) {
      this.api.approveTask(t.id).subscribe(
        data => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  declineTask(task:ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    if(confirm("¿Estás seguro que quieres declinar esta tarea?")) {
      this.api.declineTask(t.id).subscribe(
        data => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  reaskApprovalTask(task:ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    if(confirm("¿Estás seguro que quieres volver a preguntar al cliente si quiere aprobar esta tarea?")) {
      this.api.reaskApprovalTask(t.id).subscribe(
        data => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  canDrag(task:ProjectTask, board:ProjectPhaseBoardForList) {
    return board.id!=='for-approval' && !task.is_draft && (
      this.isProjectManagerOrProductManager ||
      (this.isCompanyDeveloperUser && (board.id === "backlog" || board.can_client_dev_manage_board)) ||
      (board.id === "backlog" && this.isCompanyBusinessUser)
    );
  }

  /* Private functions */

  private initSelection(){

    document.addEventListener("click", this.selectingOutListener);
    this.selectedItems = [];
  }

  private removeSelection(){
    this.selecting = false;
    this.selectingBoardId = -1;
    document.removeEventListener("click", this.selectingOutListener);
    this.selectedItems.forEach(element => {
        element.documentElement.classList.remove("task-selected");
    });

  }

  private soloSelect(board: ProjectPhaseBoardForList, task:ProjectTask|ProjectTaskBoard){
    let selectedTask = document.getElementById(task.frontendId);

      this.selectedItems.forEach(element => {
        element.documentElement.classList.remove("task-selected");
      });

      if(selectedTask != null){
        selectedTask.classList.add("task-selected");
        this.lastSelectedId = task.frontendId;
        this.selectingBoardId = board.id;
        let newSelectedItem:SelectedItem = {
          task: task,
          documentElement: selectedTask
        };

        this.selectedItems = [newSelectedItem]
      }

  }

  public ctrlSelect(board: ProjectPhaseBoardForList,task: ProjectTask|ProjectTaskBoard){
    let selectedTask = document.getElementById(task.frontendId);
    if(selectedTask && board.tasks.some(t => t.frontendId === task.frontendId)){
      selectedTask.classList.add("task-selected");
      this.lastSelectedId = task.frontendId;
      this.selectingBoardId = board.id;
      const newSelectedItem:SelectedItem = {
        task: task,
        documentElement: selectedTask
      };

      this.selectedItems.push(newSelectedItem);
    }

  }

  openCompleteDialog(board:ProjectPhaseBoardForList) {
    const dialogRef = this.dialog.open(CompleteProjectPhaseBoardDialogComponent, {
      width: '400px',
      data: {
        board: board
      }
    });

    dialogRef.afterClosed().subscribe(
      (new_board:ProjectPhaseBoard|undefined) => {
        if(new_board) {
          this.fetchProjectPhaseBoards();
        }
      }
    );
  }

  private shiftSelect(board: ProjectPhaseBoardForList, task: ProjectTask|ProjectTaskBoard){
      let lastTaskIndex = board.tasks.findIndex(t => t.frontendId === this.lastSelectedId);
      if(lastTaskIndex != -1){
        let actualTaskIndex = board.tasks.findIndex(t => t.frontendId === task.frontendId);
        if(lastTaskIndex > actualTaskIndex){
          for(let i = actualTaskIndex; i < lastTaskIndex; i++){
            let elementId = "task-"+board.tasks[i].id;
            let selectedTask = document.getElementById(elementId);
            if(selectedTask){
              selectedTask.classList.add("task-selected");
              const newSelectedItem:SelectedItem = {
                task: board.tasks[i],
                documentElement: selectedTask
              };
              this.selectedItems.push(newSelectedItem);
            }
          }
        } else {
          for(let i = actualTaskIndex; i > lastTaskIndex; i--){
            const elementId = this.getId(board.tasks[i]);
            const selectedTask = document.getElementById(elementId);
            if(selectedTask){
              selectedTask.classList.add("task-selected");
              const newSelectedItem:SelectedItem = {
                task: board.tasks[i],
                documentElement: selectedTask
              };
              this.selectedItems.push(newSelectedItem);
            }
          }
        }
      }
  }

  private documentClickListener(e:Event, self:ProjectPhaseBacklogComponent){

    let target: HTMLElement | null = e.target as HTMLElement;
    let event = e as PointerEvent;

    let metaKey = event.metaKey,
      shiftKey = event.shiftKey,
      ctrlKey = event.ctrlKey;

    if(!(metaKey || shiftKey || ctrlKey)){
      if(target){
        if(target.className != "mat-button-wrapper" && !target.className.includes("multiSelectButton")){
          self.removeSelection();
        }
      } else {
        self.removeSelection();
      }
    }
  }

  private updateTask( task: ProjectTask ) {
    this.api.updateProjectTask(task).subscribe();
  }

  private changePhaseTasksOrder(column:any) {
    const tasksOrder = column.data.map((task:any) => task.id);
    this.api.changePhaseTasksOrder(tasksOrder).subscribe();
  }

  private phaseTaskOrderPerBoard(){
    return this.boards.map((board:any) => {
      return {
        board_id: board.id !== "for-approval" && board.id !== "backlog" ? board.id : null,
        tasks: board.project_tasks.map((task:any) => {
          return {
            id: task.id,
            col_id: task.project_phase_board_col_id,
            col_type: task.project_col_type ?? null
          }
        })
      }
    });
  }

  private fetchProjectPhaseBoards() : void {
    let params = {
      show_archived_tasks: !this.show_archived_tasks ? this.show_archived_tasks.toString() : null,
      show_archived_boards: !this.show_archived_boards ? this.show_archived_boards.toString() : null,
      with: 'tags,creator..role',
      order_by: 'order_project_phase',
      order_by_direction: 'asc'
    };
    this.api.getProjectPhaseTasks(this.phaseId, params).subscribe(
      data => {
        this.boards = [];
        this.createBoardsFromBoards(data.project_phase_boards); // creem boards
        this.createBoardFromProjectTasks(data.backlog_project_tasks, data.project_phase_id, 'backlog'); // creem backlog
        this.createBoardFromProjectTasks(data.project_tasks_for_approval, data.project_phase_id, 'for-approval'); // creem backlog for approval
        this.checkOpenedTaskInParams();
      }
    );
  }

  private fetchActiveUsers() {
    this.api.getProjectActiveProductionTeam(this.projectId).subscribe(
      data => {
        this.users = data.bisual_users.map((obj:any) => obj.user);
        data.company_users.forEach(cu => this.users.push(cu));
      }
    );
  }

  private createBoardFromProjectTasks(tasks:ProjectTask[], phase_id:number, id: string|null = null) {
    const board:ProjectPhaseBoardForList = {
      id: id as any,
      title: id==='for-approval' ? 'Backlog - Por aprobar' : 'Backlog',
      tasks: tasks.map(task => { task.frontendId = this.getId(task); return task; }),
      project_phase_id: phase_id,
      can_client_dev_manage_board: false,
      can_dev_manage_board: false,
      default_followers: [],
      company_notification_channels: [],
      project_tasks_boards: [],
    };
    this.boards.push(board);
  }

  private createBoardsFromBoards(boards:ProjectPhaseBoard[]) {
    (boards as ProjectPhaseBoardForList[]).forEach(board => {
      let projectPhaseBoardForList: ProjectPhaseBoardForList = board as ProjectPhaseBoardForList;
      projectPhaseBoardForList.tasks = [];

      board.project_phase_board_cols!.forEach(col => {
        col.project_task_boards.forEach(task => {
          task.frontendId = this.getId(task);
          projectPhaseBoardForList.tasks.push(task);
        });
      });
      projectPhaseBoardForList.tasks.sort(this.compare);
      this.boards.push(projectPhaseBoardForList);
    });
  }

  private compare( a: ProjectTask|ProjectTaskBoard, b: ProjectTask|ProjectTaskBoard ) {
    const orderA = (a as ProjectTask).title!=null ? (a as ProjectTask).order_project_phase : (a as ProjectTaskBoard).project_task.order_project_phase;
    const orderB = (b as ProjectTask).title!=null ? (b as ProjectTask).order_project_phase : (b as ProjectTaskBoard).project_task.order_project_phase;
    if ( orderA < orderB ){
      return -1;
    }
    if ( orderA > orderB ){
      return 1;
    }
    return 0;
  }

  private deleteSelectedProjectTasks(){
    let ids_project_tasks: number[] = [];
    let ids_project_task_boards: number[] = [];
    let board = this.boards.find(board => board.id === this.selectingBoardId);
    this.selectedItems.forEach(element => {
      if((element.task as ProjectTask).project_phase_id != null) {
        ids_project_tasks.push(element.task.id);
      }
      else {
        ids_project_task_boards.push(element.task.id);
      }
      if(board) {
        board.tasks = board.tasks.filter(task => task.frontendId != element.task.frontendId);
      }
    });
    const data = {
      to_delete_project_tasks : ids_project_tasks,
      to_delete_project_task_boards : ids_project_task_boards,
      new_order : this.phaseTaskOrderPerBoard()
    }
    this.api.deleteMultipleTasks(data).subscribe();
  }

  private deleteTaskFromList(task:ProjectTask|ProjectTaskBoard, board: ProjectPhaseBoardForList){
    board.tasks = board.tasks.filter(listTask => listTask.frontendId != task.frontendId);
  }

  addClassDependingOnColumnType(task: ProjectTask|ProjectTaskBoard, board: ProjectPhaseBoard) {
    if((task as ProjectTask).title == null) {
      return (task as ProjectTaskBoard).project_phase_board_col.type;
    }
    else return "";
  }

  editProjectPhaseBoard(board:any) {
    const dialogRef = this.dialog.open(EditProjectPhaseBoardDialogComponent, {
      width: '1000px',
      data: {
        'board' : board,
        project_id: this.projectId,
        company_id: this.project.company_id
      }
    });

    dialogRef.afterClosed().subscribe((data:any) => {
      if(data!=null) {
        this.fetchProjectPhaseBoards();
      }
    });
  }

  createProjectPhaseBoard() {
    const data = {
      'project_phase_id' : this.phaseId,
      'type': 'sprint',
    }
    this.api.createProjectPhaseBoard(data).subscribe(
      response => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  deleteProjectPhaseBoard(board:any) {
    if(confirm("Seguro que quieres borrar este tablero?")){
      this.api.deleteProjectPhaseBoard(board.id).subscribe(
        response => {
          this.fetchProjectPhaseBoards();
        }
      );
    }
  }

  sumTotalSprintEstimation(board: ProjectPhaseBoardForList){
    let sum = 0;
    board.tasks.forEach(task => {
      sum += task.ro_time_estimation;
    });
    return sum;
  }

  async onColumnChange(board:ProjectPhaseBoard, task:ProjectTaskBoard, new_project_phase_board_col_id:number) {
    const old_project_phase_board_col_id:number = task.project_phase_board_col_id;
    task.project_phase_board_col_id = new_project_phase_board_col_id;

    const old_col:ProjectPhaseBoardCol = board.project_phase_board_cols!.find(b => b.id == old_project_phase_board_col_id) as ProjectPhaseBoardCol;
    const new_col:ProjectPhaseBoardCol = board.project_phase_board_cols!.find(b => b.id == new_project_phase_board_col_id) as ProjectPhaseBoardCol;

    const valid:boolean = ProjectPhaseBoardsComponent.checkAndAskIfTaskMoveIsValid(task, old_col, new_col);
    if(valid) {
      const dialogRef:MatDialogRef<any,any>|undefined = ProjectPhaseBoardsComponent.handleTaskFromColumnToColumnDialogs(this.dialog, task.id, old_col, new_col, +this.projectId, task.project_task_id);

      if(dialogRef) {
        const data:any = await dialogRef.afterClosed().toPromise();
      }
      this.api.updateProjectTaskBoard(task.id, task).subscribe();
    }
  }

  openTask(task_id_code:string|number, project_phase_board_id: string|number) {
    if(!this.dialogAlreadyOpened) {
      if(project_phase_board_id==='for-approval') project_phase_board_id = 'backlog';

      this.dialogAlreadyOpened = true;
      const dialogRef = this.dialog.open(ProjectTaskDetailComponent, {
        width: '1150px',
        maxHeight: '90vh',
        autoFocus: false,
        data: { task_code_id: task_id_code, project_phase_board_id: project_phase_board_id, project_id: this.projectId }
      });

      dialogRef.afterClosed().subscribe((task:any) => {
        // this.fetchProjectPhaseBoards();
        this.dialogAlreadyOpened = false;
        this.router.navigate([], {
          queryParams: {
            'selectedIssue': null,
          },
          queryParamsHandling: 'merge'
        });

        this.fetchProjectPhaseBoards();
      });
    }
  }

  downloadXlsx() {
    this.api.downloadProjectXlsxTaskCreationTemplate(+this.projectId).subscribe(
      data => {
        this.utils.downloadXlsxFromBlobResponse(data);
      }
    );
  }

  showDialogUploadTasks(board: ProjectPhaseBoardForList) {
    const dialogRef = this.dialog.open(ImportFileDialogComponent,
      {
        width: '400px',
        data: {
          title: 'Sube aquí tus tareas',
          accepted_file_types: '.xlsx',
        }
      }
    );

    dialogRef.afterClosed().subscribe((files:File[]) => {
      let processed:boolean = false;
      for(let file of files) {
        if(!processed && file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
          processed = true;
          this.api.saveBulkProjectTasksXlsx({board_id: board.id, project_phase_id: this.project_phase.id}, file).subscribe(
            data => {
              board.tasks = board.tasks.concat(data);
              this.stopCreatingTask();
            }
          );
        }
      }
    });
  }

  private importTasksBulk(tasks: any[], board: ProjectPhaseBoardForList) {
    this.api.saveBulkProjectTasks(tasks).subscribe(
      data => {
        board.tasks = board.tasks.concat(data);
        this.stopCreatingTask();
      }
    );
  }

  public archiveTask(task: ProjectTask|ProjectTaskBoard) {
    const t:ProjectTask = (task as ProjectTask).title != null ? task as ProjectTask : (task as ProjectTaskBoard).project_task;
    this.api.archiveTasks({ tasks_ids: [t.id], new_status_archived: !t.is_archived }).subscribe(
      data => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  public archiveTasksFromBoard(board:ProjectPhaseBoard, new_status_archived:boolean) {
    // get all ids from tasks in every column of the board
    let tasks_ids = board.project_phase_board_cols?.map(col => col.project_task_boards.map(task => task.project_task.id)).flat();
    this.api.archiveTasks({ tasks_ids: tasks_ids, new_status_archived: new_status_archived }).subscribe(
      data => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  public archiveBoard(board:ProjectPhaseBoard) {
    this.api.archiveBoard(board.id!.toString()).subscribe(
      data => {
        this.fetchProjectPhaseBoards();
      }
    );
  }

  private initIsPMorPO() {
    this.isProductManager = this.permissions.isWorkerWithProjectRole(this.projectId, 'product-manager');
    this.isProjectManagerOrProductManager = this.permissions.isWorkerWithProjectRole(this.projectId, 'project-manager') || this.isProductManager;
    this.isWorker = this.permissions.isWorker();
    this.isCompanyBusinessUser = this.permissions.isClientBusiness();
    this.isCompanyDeveloperUser = this.permissions.isClientDeveloper();
  }

  private fetchProject() {
    this.api.getProject(this.projectId).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  private fetchProjectPhase() {
    this.api.getProjectPhase(+this.phaseId).subscribe(
      data => {
        this.project_phase = data;
      }
    );
  }
}

/* Extra models */

export interface ProjectPhaseBoardForList extends ProjectPhaseBoard {
  id: string;
  tasks: (ProjectTask|ProjectTaskBoard)[];
}

interface SelectedItem {
  task:ProjectTask|ProjectTaskBoard;
  documentElement: HTMLElement;
}
